<template>
  <v-container>
    <v-form @submit.prevent="submitResponse">
      <div>
        <p class="mb-3">
          Three grams of gas are placed in a sealed 10-L flask maintained at 0°C. The molecular
          weight of the gas is 20 amu. Which of the following would most likely occur if the valve
          to the flask is opened?
        </p>

        <p class="mb-8">
          Answer each of the five statements as True or False. Explain each of your answers. You
          will be graded based on your choice (True or False) and on the correctness or
          appropriateness of your explanations. You may use calculations, common sense, examples,
          analogies, etc., for your explanation. Your Teaching Assistant (TA) will review your
          answers for this question. Please allow up to two days for your TA to review and
          potentially adjust your grade.
        </p>

        <div v-for="question in trueFalseQuestions" :key="question" class="mt-3 pl-3">
          <v-row class="mb-2">
            <stemble-latex :content="question.option" />
          </v-row>
          <s-textarea v-model="inputs[question.inputId]" outlined class="mb-5"></s-textarea>
        </div>
      </div>
    </v-form>
    <ai-loading-overlay v-if="isSubmitting" />
  </v-container>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'OleMissPreLab11Part3',
  components: {
    AiLoadingOverlay,
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        trueFalseA: null,
        trueFalseB: null,
        trueFalseC: null,
        trueFalseD: null,
        trueFalseE: null,
      },
      trueFalseQuestions: [
        {
          option: 'a) The gas and oxygen in the air will mix and react.',
          inputId: 'trueFalseA',
        },
        {
          option: 'b) The gas will rush out of the flask.',
          inputId: 'trueFalseB',
        },
        {
          option: 'c) At 0°C, the 3 g of gas will still occupy 10 liters.',
          inputId: 'trueFalseC',
        },
        {
          option: 'd) Air will rush into the flask.',
          inputId: 'trueFalseD',
        },
        {
          option: 'e) The gas and oxygen will remain separate and not mix.',
          inputId: 'trueFalseE',
        },
      ],
    };
  },
};
</script>

<style scoped></style>
